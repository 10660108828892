<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      class="search-form"
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :needBtnLoading="true"
      round
      :labelWidth="80"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
        class="el_from"
        label="渠道选择"
        slot="operationSelect1"
        style="margin: 0"
      >
        <el-select
          v-model="matchChannelId"
          placeholder="请选择撮合渠道"
          filterable
          clearable
        >
          <el-option
            v-for="item in channelSelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="回收商家"
        slot="operationSelect2"
        style="margin: 0"
      >
        <el-select
          v-model="merchantId"
          placeholder="请选择商家名称"
          filterable
          clearable
          clear="clearMerchant"
          @change="handleChange"
        >
          <el-option
            v-for="item in merchantSelectList"
            :key="item.merchantId"
            :label="item.merchantName"
            :value="item.merchantId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="门店商家"
        slot="operationSelect3"
        style="margin: 0"
      >
        <el-select
          v-model="companyId"
          placeholder="请选择商家名称"
          filterable
          clearable
        >
          <el-option
            v-for="item in companySelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="创建时间"
        slot="operationSelect4"
        style="margin: 0"
      >
        <el-date-picker
          v-model="entryTime"
          type="daterange"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <!-- <div slot="AddSlot">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="addMatchm"
          >创建撮合交易</el-button
        >
        <el-button
          icon="el-icon-download"
          size="small"
          type="success"
          @click="exportExcel"
          >导出Excel</el-button
        >
      </div> -->
    </GlobalForm>
    <div style="text-align: right;margin-bottom: 20px" slot="AddSlot">
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="addMatchm"
        >创建撮合交易</el-button
      >
      <el-button
        icon="el-icon-download"
        size="small"
        type="success"
        :loading="exportLoading"
        @click="exportExcel"
        >导出Excel</el-button
      >
    </div>
    <!--弹框-->
    <el-dialog
      class="add-form"
      title="添加撮合交易"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
      @closed="handleDialogClosed"
    >
      <div style="color: #ff8080; font-size: 14px; margin-bottom: 20px">
        说明：撮合交易生效后，系统会按照设置订单扣款条件对撮合回收商家进行收货次数扣减。
      </div>
      <el-form
        ref="form"
        :rules="modelrules"
        :model="modelform"
        label-width="80px"
      >
        <el-form-item label="创建方式" prop="addType">
            <el-radio-group v-model="modelform.addType" @change="addTypeChange">
              <el-radio :label="'00'">批量选择回收商创建</el-radio>
              <el-radio :label="'01'">批量选择门店商创建</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="渠道选择" prop="matchChannelId">
          <el-select
            v-model="modelform.matchChannelId"
            placeholder="请选择撮合渠道"
            filterable
            clearable
            @change="formMatchChange"
          >
            <el-option
              v-for="item in channelSelectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <div v-if="modelform.addType == '00'">
          <el-form-item label="门店商家" prop="companyId">
            <el-select
              v-model="modelform.companyId"
              placeholder="请选择门店商家"
              :no-data-text="modelform.matchChannelId?'暂无数据':'请先选择撮合渠道'"
              filterable
              clearable
              @change="formCompanyChange"
            >
              <el-option
                v-for="item in companyMatchSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="撮合商家" prop="merchantId">
            <el-select
              v-model="modelform.merchantId"
              placeholder="请选择回收商家"
              multiple
              :no-data-text="modelform.companyId?'暂无数据':'请先选择门店商家'"
              filterable
              clearable
            >
              <el-option
                v-for="item in allMerchantSelectList"
                :key="item.merchantId"
                :label="item.merchantName"
                :value="item.merchantId"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="modelform.addType == '01'">
            <el-form-item label="撮合商家" prop="merchantId">
              <el-select
                v-model="merchantIds"
                placeholder="请选择回收商家"
                :no-data-text="modelform.matchChannelId?'暂无数据':'请先选择撮合渠道'"
                filterable
                clearable
                @change="merchantChange"
              >
                <el-option
                  v-for="item in allMerchantSelectList"
                  :key="item.merchantId"
                  :label="item.merchantName"
                  :value="item.merchantId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="门店商家" prop="companyId">
            <el-select
              v-model="companyIds"
              placeholder="请选择门店商家"
              :no-data-text="modelform.merchantId?'暂无数据':'请先选择撮合商家'"
              filterable
              multiple
              clearable
              @change="chooseAddStore"
            >
              <el-option
                v-for="item in companyMatchSelectList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="扣款条件" required style="margin-bottom: 0;">
          <el-button
            style="float: right;"
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addRule"
            >添加规则</el-button
          >
        </el-form-item>
        <GlobalTable
          style="margin-bottom:22px"
          ref="GlobalTable"
          myKey="deduction"
          :maxHeight="270"
          :columns="deductionColumns"
          :isPagination="false"
          :data="middleRegionVos"
        >
          <el-table-column label="成交订单报价" slot="rank" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            :label-width="'200px'"
            slot="operation"
            align="center"
          >
            <template slot-scope="{ row, $index }">
              <el-button
                size="mini"
                type="primary"
                round
                @click="handleBtn(row, 'edit', $index)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                round
                @click="handleBtn(row, 'del', $index)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </GlobalTable>

        <!-- <el-form-item label="渠道扣款" prop="channelTax">
          <el-input
            placeholder="请输入0或正整数"
            maxlength="10"
            v-model.trim="modelform.channelTax"
            @input="moneyInput(modelform, 'channelTax', modelform.channelTax)"
          >
            <template slot="append">次（收货次数）</template>
          </el-input>
        </el-form-item>
        <el-form-item label="繁星扣款" prop="btstarsTax">
          <el-input
            placeholder="请输入0或正整数"
            maxlength="10"
            v-model.trim="modelform.btstarsTax"
            @input="moneyInput(modelform, 'btstarsTax', modelform.btstarsTax)"
          >
            <template slot="append">次（收货次数）</template>
          </el-input>
        </el-form-item>
        <el-form-item label="扣款条件" prop="takeEffectPrice">
          <el-input
            placeholder="请输入正整数"
            v-model.trim="modelform.takeEffectPrice"
            @input="
              (val) =>
                (modelform.takeEffectPrice = val
                  .replace(/[^\d]/g, '')
                  .replace(/^0$/, ''))
            "
          >
            <template slot="prepend">订单实际报价≥</template>
            <template slot="append">元</template>
          </el-input>
        </el-form-item> -->
        <el-form-item label="生效时间" prop="takeEffectTime">
          <div class="addFenTime">
            <el-date-picker
                v-model="modelform.takeEffectTime"
                type="date"
                :disabled="isImmediate"
                :picker-options="pickerOptions"
                :editable="false"
                value-format="timestamp"
                @change="completionTimeFun"
                placeholder="请选择生效时间"
            >
            </el-date-picker>
            <div class="raido_inner" @click="addFenTimeChange">
              <div :class="isImmediate?'active_select':''"></div>
              <p>立即生效</p>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="dialogMiddleRegionTitle"
      :visible.sync="dialogMiddleRegionVisible"
      :close-on-click-modal="false"
      width="550px"
      @closed="middleRegionDialogClosed"
    >
      <div class="rules-form">
        <div class="rule-title">
          <img class="tip_ico" src="@/assets/images/ico_ts_red.png" alt />
          <span>说明:按照撮合商家合作后成交订单报价进行扣款</span>
        </div>
        <el-form
          :model="middleRegion"
          :rules="middleRegionRules"
          ref="middleRegion"
          label-width="82px"
          class="demo-ruleForm"
        >
          <el-form-item label="报价区间:" required>
            <el-col :span="11">
              <el-form-item prop="lower">
                <el-input
                  v-model.trim="middleRegion.lower"
                  oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入起始值"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col style="text-align: center" :span="2">~</el-col>
            <el-col :span="11">
              <el-form-item prop="upper">
                <el-input
                  v-model.trim="middleRegion.upper"
                  oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入结束值"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="渠道扣款" prop="channelTax">
            <el-input
              placeholder="请输入0或正整数"
              maxlength="10"
              v-model.trim="middleRegion.channelTax"
              @input="
                moneyInput(middleRegion, 'channelTax', middleRegion.channelTax)
              "
            >
              <template slot="append">次（收货次数）</template>
            </el-input>
          </el-form-item>
          <el-form-item label="繁星扣款" prop="btstarsTax">
            <el-input
              placeholder="请输入0或正整数"
              maxlength="10"
              v-model.trim="middleRegion.btstarsTax"
              @input="
                moneyInput(middleRegion, 'btstarsTax', middleRegion.btstarsTax)
              "
            >
              <template slot="append">次（收货次数）</template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogMiddleRegionVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click.native="middleRegHandleSuccess"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="删除提示"
      :visible.sync="dialogDelVisible"
      :close-on-click-modal="false"
      width="380px"
      @closed="dialogDelVisible = false"
    >
      <div class="del-title">
        <div class="sure_tip">确认要删除该数据吗？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogDelVisible = false">取消</el-button>
        <el-button type="primary" @click.native="handleSuccessDel"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="安全验证"
      :visible.sync="commandDisableShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="commandDialogClosed"
    >
      <div class="command">
        <div style="width: max-content">谷歌验证码：</div>
        <el-input
          v-model="modelform.command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandDisableShow = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="commandDisableSure"
          :loading="butLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
import { MessageBox } from "element-ui";

let checkPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入正确的手机号码"));
  } else {
    callback();
  }
};
let checkPrice = (rule, value, callback) => {
  if (value < 50) {
    callback(new Error("扣款条件不能小于50元"));
  } else {
    callback();
  }
};
export default {
  name: "Form",
  props: {
    merchantSelectList: {
      type: Array,
    },
    // allMerchantSelectList: {
    //   type: Array,
    // },
    channelSelectList: {
      type: Array,
    },
  },
  data() {
    return {
      // 立即生效
      isImmediate: null,
      initData: null,
      dialogVisible: false,
      matchChannelId: "",
      merchantId: "",
      companyId: "",
      entryTime: [
          moment()
            .day(moment().day()-30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      companySelectList: [],
      companyMatchSelectList: [],
      allMerchantSelectList: [],
      companyIdList: [],
      merchantIds: "",
      companyIds: "",
      merchantIdList: [],
      formItemList: [{ slotName: "operationSelect1" },{ slotName: "operationSelect2" },{ slotName: "operationSelect3" },{ slotName: "operationSelect4" }],
      modelform: {
        matchChannelId: "",
        merchantId: "",
        companyId: "",
        takeEffectTime: "",
        command: "",
        addType: "00"
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now(); //禁止选择今天和今天之前的时间
        },
      },
      SeachParams: {},
      formRules: {},
      modelrules: {
        addType: [
          {
            required: true,
            message: "请选择创建方式",
            trigger: ["blur", "change"],
          },
        ],
        matchChannelId: [
          {
            required: true,
            message: "请选择撮合渠道",
            trigger: ["blur", "change"],
          },
        ],
        merchantId: [
          {
            required: true,
            message: "请选择回收商家",
            trigger: ["blur", "change"],
          },
        ],
        companyId: [
          {
            required: true,
            message: "请选择门店商家",
            trigger: ["blur", "change"],
          },
        ],
        btstarsTax: [
          { required: true, message: "请输入繁星扣款", trigger: "blur" },
        ],
        takeEffectPrice: [
          { required: true, message: "请输入扣款条件", trigger: "blur" },
          { type: "number", validator: checkPrice, trigger: "blur" },
        ],
        takeEffectTime: [
          {
            required: true,
            message: "请选择生效时间",
            trigger: ["blur", "change"],
          },
        ],
      },
      butLoading: false,
      middleRegionVos: [],
      deductionColumns: [
        { slotName: "rank" },
        { label: "渠道扣款(次/收货次数)", prop: "channelTax" },
        { label: "繁星扣款(次/收货次数)", prop: "btstarsTax" },
        { slotName: "operation" },
      ],
      middleRegionRules: {
        lower: [
          { required: true, message: "请输入报价区间起始值", trigger: "blur" },
        ],
        upper: [
          { required: true, message: "请输入报价区间结束值", trigger: "blur" },
        ],
        channelTax: [
          { required: true, message: "请输入渠道扣款", trigger: "blur" },
        ],
        btstarsTax: [
          { required: true, message: "请输入繁星扣款", trigger: "blur" },
        ],
      },
      rowId: "",
      middleRegion: {
        lower: "",
        upper: "",
        btstarsTax: "",
        channelTax: "",
      },
      dialogMiddleRegionTitle: "",
      dialogMiddleRegionVisible: false,
      dialogDelVisible: false,
      commandDisableShow: false,
      exportLoading: false,
    };
  },
  created() {
    this.handleConfirm();
    this.getMatchCompanyList();
  },
  watch: {
    "modelform.matchChannelId"(val) {
      this.modelform.companyId = "";
      this.modelform.merchantId = [];

    },
    "modelform.companyId"(val) {
      this.modelform.merchantId = [];
    },
    isImmediate(v){
      if(v){
        this.modelform.takeEffectTime = Number(moment().format("x"));
      }
    }
  },
  methods: {
    // 生效时间
    addFenTimeChange() {
      this.isImmediate = !this.isImmediate;
    },
    chooseAddStore (e) {
      if (e) {
        this.companyIdList = e
        this.modelform.companyId = e
      } else {
         this.modelform.companyId = ''
      }

    },
    moneyInput(obj, prop, value) {
      value = "" + value.replace(/[^0-9]/g, "");
      if (!value) {
        this.$set(obj, prop, "");
        return;
      }
      value = parseInt(value);
      this.$set(obj, prop, value);
    },
    handleConfirm(data,cd) {
      if (this.entryTime === null) {
        (this.startTime = ""), (this.endTime = "");
      } else {
        (this.startTime = moment(this.entryTime[0]).format("x")),
          (this.endTime = moment(this.entryTime[1]).format("x"));
      }
      console.log(this.startTime);
      console.log(this.endTime);
      const SeachParams = {
        merchantId: this.merchantId,
        companyId: this.companyId,
        endTime: this.endTime,
        matchChannelId: this.matchChannelId,
        startTime: this.startTime,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      };
      this.SeachParams = SeachParams;
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    addTypeChange (e) {
      this.$refs.form.resetFields()
      if (e == '01') {
        this.merchantIds = ""
        this.companyIds = ""
        this.modelform= {
          matchChannelId: "",
          merchantId: "",
          companyId: "",
          takeEffectTime: "",
          command: "",
          addType: "01"
        }
        this.allMerchantSelectList = []
        this.companyMatchSelectList = []   
      } else {
        this.modelform= {
          matchChannelId: "",
          merchantId: [],
          companyId: "",
          takeEffectTime: "",
          command: "",
          addType: "00"
        }
        this.allMerchantSelectList = []
        this.companyMatchSelectList = []   
      }
    },
    addMatchm() {

      this.dialogVisible = true;
      // 去除默认选择
      this.isImmediate = false;
      this.$nextTick(() =>{ 
        this.$refs.form.resetFields()
      })
    },
    handleDialogClosed() {
      this.$refs["form"].resetFields();
      this.companyMatchSelectList = []
      this.allMerchantSelectList = []
      this.dialogVisible = false;
    },
    merchantChange (e) {
      if (e) {
        this.merchantIdList = [e]
        this.modelform.merchantId = this.merchantIdList 
        this.getMoreSelectListMatch(e)
      } else {
        this.modelform.merchantId = ""
      }

    },
    // 确认添加
    handleSuccess() {
      if (this.modelform.addType == '01') {
        this.modelform.merchantId = this.merchantIdList
        this.modelform.companyId = this.companyIdList
      }
      console.log(this.modelform, 'this.modelform');
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.commandDisableShow = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取门店商家
    getMatchCompanyList() {
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          this.companySelectList = res.data;
        }
      });
    },
    //门店商家下拉列表（撮合交易）
    getSelectListMatch() {
      _api
        .getSelectListMatch({ matchId: this.modelform.matchChannelId })
        .then((res) => {
          if (res.code === 1) {
            this.companyMatchSelectList = res.data;
          }
        });
    },
    //门店多商家下拉列表（撮合交易）
    getMoreSelectListMatch(e) {
      _api
        .getSelectListMatchMiddle({ merchantId: e,matchId: this.modelform.matchChannelId })
        .then((res) => {
          if (res.code === 1) {
            this.companyMatchSelectList = res.data;
          }
        });
    },
    // 已合作回收商家下拉列表
    getMiddleAdoptMerchantMatch() {
      _api
        .getMiddleAdoptMerchantMatch({
          companyId: this.modelform.companyId,
          matchId: this.modelform.matchChannelId,
        })
        .then((res) => {
          if (res.code === 1) {
            this.allMerchantSelectList = res.data;
            console.log(res.data);
          }
        });
    },
    getMoreMiddleMerchant () {
        _api
        .matchMoreMerchantList()
        .then((res) => {
          if (res.code === 1) {
            this.allMerchantSelectList = res.data;
            this.$refs.form.clearValidate()
          }
        })
    },
    // 添加生效时间时间
    completionTimeFun(e) {
      console.log(e);
    },
    // 选择回收商家
    handleChange(val) {
      this.merchantId = val;
    },
    // 选择撮合渠道回调
    formMatchChange(e) {
      if (this.modelform.addType == '00') {
        this.getSelectListMatch();
      }  else {
        this.getMoreMiddleMerchant()
      }
    },
    // 选择门店商家回调
    formCompanyChange(e) {
      this.companyIdList = [e]
      this.getMiddleAdoptMerchantMatch();
    },
    exportExcel(e) {
      this.exportLoading = true;
      _api.matchChannelRelationExcel(this.SeachParams).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              MessageBox.alert(data.msg, "错误提示", {
                type: "error",
              });
            }
          } catch (err) {
            const fileName = "撮合交易商家明细.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
      }).finally(() => {
        this.exportLoading = false;
      });
    },
    // 添加扣款规则
    addRule() {
      this.tableOperation = "add";
      this.rowId = "";
      this.dialogMiddleRegionTitle = "添加规则-撮合扣款";
      this.dialogMiddleRegionVisible = true;
    },
    // 扣款条件表格操作
    handleBtn(row, type, index) {
      this.rowId = index;
      this.tableOperation = type;
      if (type == "edit") {
        this.dialogMiddleRegionTitle = "编辑规则-撮合扣款";
        this.middleRegion.lower = row.lower;
        this.middleRegion.upper = row.upper;
        this.middleRegion.btstarsTax = row.btstarsTax;
        this.middleRegion.channelTax = row.channelTax;
        this.dialogMiddleRegionVisible = true;
      } else {
        this.dialogDelVisible = true;
      }
    },
    middleRegionDialogClosed() {
      this.middleRegion = {
        lower: "",
        upper: "",
        btstarsTax: "",
        channelTax: "",
      };
      this.$refs["middleRegion"].resetFields();
    },
    // 添加规则-撮合扣款弹框确定
    middleRegHandleSuccess() {
      this.$refs["middleRegion"].validate((valid) => {
        if (valid) {
          let middleRegion = this.middleRegion;
          if (Number(middleRegion.lower) > Number(middleRegion.upper)) {
            this.$message.error("起始值不能大于结束值");
            return;
          }
          if (Number(middleRegion.lower) == Number(middleRegion.upper)) {
            this.$message.error("起始值不能和结束值相等");
            return;
          }
          let params = {
            lower: middleRegion.lower,
            upper: middleRegion.upper,
          };
          if (this.middleRegionVos.length && this.tableOperation != "edit") {
            let numArr = [];
            this.middleRegionVos.forEach((item) => {
              numArr.push(item.lower);
              numArr.push(item.upper);
            });
            let iMin = Math.min(...numArr); //最小值
            let iMAX = Math.max(...numArr); //最大值
            if (middleRegion.upper != iMin && middleRegion.lower != iMAX) {
              this.$message.error("价位区间不连续");
              return;
            }
            if (middleRegion.lower != iMAX && middleRegion.upper != iMin) {
              this.$message.error("价位区间不连续");
              return;
            }
          }
          params.btstarsTax = middleRegion.btstarsTax;
          params.channelTax = middleRegion.channelTax;
          if (this.rowId.toString()) {
            this.middleRegionVos.splice(this.rowId, 1, params);
          } else {
            this.middleRegionVos.push(params);
          }
          this.dialogMiddleRegionVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除弹框确认
    handleSuccessDel() {
      this.middleRegionVos.splice(this.rowId, 1);
      this.dialogDelVisible = false;
    },
    commandDialogClosed() {
      this.modelform.command = "";
    },
    // 谷歌验证弹框确定
    commandDisableSure() {
        let params = {
        middleRegionVos: this.middleRegionVos,
        ...this.modelform,
        merchantIdList:this.modelform.merchantId,
        companyIdList: this.companyIdList
      };
      if (this.modelform.addType == '01') {
        params.merchantIdList = this.merchantIdList
        params.companyIdList = this.modelform.companyId
      } else {
        params.merchantIdList = this.modelform.merchantId
      }
      const isImmediate = this.isImmediate;
      if(isImmediate){
        params = {
          ...params,
          takeEffectTime:undefined,
          isImmediate
        }
      }
      this.butLoading = true;
      // addMatchTransaction
      _api
        .addMatchsTransaction(params)
        .then((res) => {
          this.$message.success("添加成功");
          this.dialogVisible = false;
          this.commandDisableShow = false;
          this.handleConfirm(this.SeachParams);
          this.butLoading = false;
          this.middleRegionVos = [];
        })
        .catch(() => {
          this.butLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  /deep/ .el-form--inline .el-form-item {
    margin-right: 20px !important;
  }
  .search-form {
    /deep/.el-select > .el-input {
      width: 170px !important;
    }
  }
  .add-form {
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
    /deep/.el-input-group__prepend {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
    /deep/.el-select {
      width: 100%;
    }
  }
  .rules-form {
    .rule-title {
      display: flex;
      align-items: center;
      color: #ff8080;
      margin-bottom: 20px;
    }
    .tip_ico {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
  }
  .del-title {
    color: #ff8080;
    font-size: 12px;
    .sure_tip {
      margin-top: 15px;
      font-size: 16px;
      color: #333333;
    }
  }
  .command {
    display: flex;
    align-items: center;
    /deep/.el-input {
      width: 300px;
    }
  }
}


.addFenTime {
  width: 100%;
  display: flex;
  align-items: center;

  > div:first-of-type {
    width: 60% !important;
    margin-right: 20px;
  }

  > div:last-of-type {
    flex: auto;
  }
}

.raido_inner {
  display: flex;
  align-items: center;
  cursor: pointer;
  > div {
    border:1px solid #0981FF;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #fff;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
    }
  }
  >p{
    font-size: 14px;
    margin-left: 10px;
  }
  .active_select{
    border-color: #0981FF;
  }
  .active_select::after{
    background: #0981FF;
  }
}
</style>