<template>
  <div class="class-list">
    <GlobalInfoBar
      title="撮合交易商家管理"
      content="管理撮合交易商家"
    />
    <!-- <Info/> -->
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <From :channelSelectList="channelSelectList" :merchantSelectList="merchantSelectList" :allMerchantSelectList="allMerchantSelectList"/>
      </div>
      <Table :channelSelectList="channelSelectList" :merchantSelectList="merchantSelectList" :allMerchantSelectList="allMerchantSelectList"/>
    </GlobalChunk>
  </div>
</template>

<script>
import From from "./Form";
import Table from "./Table";
import _api from "@/utils/request";

export default {
  name: "class-list",
  components: {
    From,
    Table
  },
  data() {
    return {
      channelSelectList: [],
      merchantSelectList: [],
      allMerchantSelectList: []
    };
  },
  created() {
    this.getMatchChannels()
    this.getMatchMerchantList()
    // this.listSimpleMerchantView()
  },
  methods: {
        // 获取渠道
    getMatchChannels() {
      _api.getMatchChannelSelect().then(res => {
        if (res.code === 1) {
          this.channelSelectList = res.data;
        }
      });
    },
    // 获取回收商家
    getMatchMerchantList() {
      _api.matchMerchantList({pageNum: 1,pageSize:999}).then(res => {
        if (res.code === 1) {
          this.merchantSelectList = res.data;
        }
      });
    },
    // 获取所有回收商家
    listSimpleMerchantView() {
      _api.listSimpleMerchantView({pageNum: 1,pageSize:999,isNice: '00'}).then(res => {
        if (res.code === 1) {
          this.allMerchantSelectList = res.data;
        }
      });
    },
  },
};
</script>

<style>
</style>